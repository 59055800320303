import { template as template_7564cb622ffc4ee5a71631fb5e0ff8fd } from "@ember/template-compiler";
const FKText = template_7564cb622ffc4ee5a71631fb5e0ff8fd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
