import { template as template_9e702f43a603463893579e9acfcaac80 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9e702f43a603463893579e9acfcaac80(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
